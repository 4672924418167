<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>

      <div class="card-header-actions">
        <CButton color="dark" :to="this.prevRoute">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <CForm>
        <CRow>
          <!-- Name -->
          <CCol col="6">
            <CInput
              label="Name"
              placeholder="Name"
              :lazy="false"
              :value.sync="$v.form.name.$model"
              :isValid="checkIfValid('name')"
              invalidFeedback="This is a required field"
            />
          </CCol>

          <!-- Note -->
          <CCol col="6">
            <CInput
              label="Note"
              placeholder="Note"
              :lazy="false"
              :value.sync="$v.form.notes.$model"
            />
          </CCol>

          <!-- Restaurant -->
          <CCol col="12">
            <CSelect
              custom
              label="Restaurant"
              placeholder="Please choose a restaurant.."
              :options="allRestaurants"
              :value.sync="$v.form.restaurant_id.$model"
              @update:value="id => getRestaurantAllCategories(id)"
              :isValid="checkIfValid('restaurant_id')"
              invalidFeedback="This is a required field"
              v-if="restaurantId == null"
            />
            <div v-else class="form-group">
              <label class="d-block" style="font-weight: 500">Restaurant</label>
              <strong class="form-control" v-text="selectedRestaurant ? selectedRestaurant.label : ''"></strong>
            </div>
          </CCol>

          <!-- Categories -->
          <CCol col="12" class="card">
            <div class="form-group">
              <label class="d-block" style="font-weight: 500">Categories</label>
              <v-select
                multiple
                class="v-select-style"
                v-model="$v.form.categories.$model"
                :options="allCategories"
                placeholder="Select categories.."
                :disabled="form.restaurant_id == null"
              />
            </div>

            <div v-for="category in this.form.categories" v-bind:key="category.id" class="category-menu">
              <h6 class="d-inline-block">{{ category.label }}</h6><span class="small text-danger"> (Tick the following to be <u>excluded!</u>)</span>
              <hr />
              <ul>
                <li v-for="menu in category.menus" v-bind:key="menu.id">

                  <label>
                    <input
                      type="checkbox"
                      v-model="form.disabled_menus"
                      :value="{category_id:menu.menu_cat, menu_id:menu.id }"
                    />
                    {{ menu.menu_name }}
                  </label>
                </li>
                <li v-if="category.menus && category.menus.length == 0"><strong>No menu groups found!</strong></li>
              </ul>
            </div>
          </CCol>
        </CRow>

        <div class="sticky-search d-flex flex-row">
          <div class="input-group flex-grow-1 w-auto mr-2">
            <div class="input-group-prepend">
              <span class="input-group-text"><CIcon name="cil-magnifying-glass"/></span>
            </div>
            <input v-model="search" type="search" class="form-control" placeholder="Search for menu groups..">
          </div>

          <div class="align-self-start ml-auto">
            <CButton variant="outline" color="primary" :pressed.sync="isCollapsed">
              <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" /> {{isCollapsed ? 'Expand' : 'Collapse'}}
            </CButton>
          </div>
        </div>

        <CRow>
          <CCol col="12" class="mt-2 table-responsive">
            <h5>Menu Groups (ingredients) Choice</h5>

            <table class="table">
              <thead>
                <tr>
                  <th style="width: 50px"></th>
                  <th style="width: 50px"><CIcon name="cil-move" /></th>
                  <th style="width: 20vw">Menu Groups</th>
                  <th style="width: 80vw">Ingredients</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="mg in allMenuGroups" :key="mg.id" :class="isSelectedMenuGroup(mg.id) ? 'selected-item': 'disable-item'">
                <!-- <tr v-for="mg in allMenuGroups" :key="mg.id" :class="mg.selected == 1 ? 'selected-item': 'disable-item'"> -->
                  <td class="enable-item">
                    <CInputCheckbox
                      addLabelClasses="custom-checkbox-label"
                      custom
                      @update:checked="(value) => selectMenuGroup(mg.id, value)"
                      :checked="isSelectedMenuGroup(mg.id)"
                    />
                    <!-- (v3) :checked="mg.selected == 1" -->
                    <!-- (v2) :checked="isSelectedMenuGroup(mg.id)" -->
                    <!-- (v1) :checked="selectedChilds.filter(child => child.menuExtGroupId == mg.id).length > 0" -->
                  </td>

                  <td class="move-item">
                    <CIcon
                      name="cil-move"
                      :data-smg-id="getSelectedMenuGroupId(mg.id)"
                      :data-mg-id="mg.id"
                      class="align-bottom"
                      size="lg"
                    />
                  </td>

                  <td style="vertical-align: top !important;">
                    <h5>{{mg.Name}}</h5>
                    <span>{{mg.Notes}}</span>

                    <section class="border-top pt-3" v-if="isSelectedMenuGroup(mg.id)">
                      <div class="form-group">
                        <label class="mr-1">Type:</label>
                        <select @change="changeTypeListing(mg.id, $event)">
                          <option value='SLT'>ListBox</option>
                          <option value='CHK' :selected="selectedTypeListing(mg.id, 'CHK')">CheckBox</option>
                        </select>
                      </div>

                      <div class="form-group w-100">
                        <label class="d-block">Valid Days *</label>
                        <div class="day-group">
                          <div v-for="(day, index) in days" class="form-check form-check-inline mr-1 mt-1">
                            <CInputCheckbox
                              :label="day"
                              :checked="selectedValidDays(mg.id)?.includes(index)"
                              @update:checked="updateValidDays(mg.id, index)"
                            />
                          </div>
                        </div>
                        <small>* All days are valid if none is selected</small>
                      </div>
                    </section>
                  </td>

                  <td class="enable-item">
                    <CCollapse :show="isCollapsed">
                      <ul>
                        <li class="text-muted"><CIcon name="cil-chevron-bottom" /> Collapsed</li>
                      </ul>
                    </CCollapse>

                    <transition name="fade">
                      <CCollapse :show="!isCollapsed" :duration="400">
                        <ul>
                          <li v-for="child in mg.menu_ext_group_childs" v-bind:key="child.id">
                            <label class="for-checkbox">
                              <input
                                type="checkbox"
                                v-model="form.selectedChilds"
                                :value="{menuExtGroupId:mg.id, menuExtGroupChildId:child.id}"
                                @change="selectingChild(mg.id)"
                              />
                              {{ child.Name }} [{{ child.Price | toCurrency }}]
                            </label>
                            <!--
                              @change="sss(mg.id, child.id, $event)"
                            -->
                          </li>
                          <li v-if="mg.menu_ext_group_childs.length == 0"><strong>No menu group childs found!</strong></li>
                        </ul>
                      </CCollapse>
                    </transition>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>

    <CCardFooter class="sticky-bottom">
      <CButton
        color="primary"
        :disabled="submitted"
        @click="isEdit ? update() : store()"
      >
        Submit
      </CButton>
    </CCardFooter>

    <mc-spinner :opacity="0.8" v-show="submitted" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCard>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';  // Import Specific Lodash Functions
import Sortable from "sortablejs";

export default {
  name: 'LinkedMenuItem',
  data() {
    return {
      form: {},
      isEdit: false,
      submitted: false,

      itemName: '',

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Get previous page
      prevRoute: null,

      // Restaurants
      allRestaurants: [],
      selectedRestaurant: null,

      // Categories
      allCategories: [],
      selectedCategories: [],

      // for All Menu Groups and Childs
      // URL params
      activePage: 1,
      itemsPerPage: 10,
      // Response data
      allMenuGroups: [],
      pages: 1,
      to: 0, // Current total
      total: 0,

      // Element Cover Spinner için
      mcSpinnerMarginTop: 0,

      // Search
      search: "",

      // Collapse Menu Groups (ingredients)
      isCollapsed: false,

      days: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    }
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  watch: {
    search: function (newQuery, oldQuery) {
      this.debouncedGetMenuGroups()
    }
  },

  created() {
    this.debouncedGetMenuGroups = _debounce(() => {
      this.activePage = 1;
      this.allMenuGroups = [];
      this.getAllMenuGroups();
    }, 1000);
  },

  async mounted() {
    this.submitted = true;
    await this.getAllRestaurantNames();
    await this.getRestaurantAllCategories(this.restaurantId);

    if(this.itemId) {
      this.form = await this.getForm(this.itemId)
      this.isEdit = true
    }
    else
      this.form = await this.getForm()

    await this.getNextMenuGroups();
    await this.getAllMenuGroups();

    //#region Sortablejs işlemleri
    var itemID, newIndex, itemMgID;
    var el = document.getElementsByTagName("tbody");

    var sortable = Sortable.create(el[0], {
      handle: "svg.c-icon",
      chosenClass: "table-selected",
      animation: 150,
      easing: "cubic-bezier(1, 0, 0, 1)",

      onSort: function (/**Event*/ evt) {
        var itemEl = evt.item; // dragged HTMLElement
        newIndex = evt.newIndex;
        itemID = itemEl.getElementsByTagName("svg")[0].getAttribute("data-smg-id");
        itemMgID = itemEl.getElementsByTagName("svg")[0].getAttribute("data-mg-id");
        // console.log(itemID, newIndex);
      },
      store: {
        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: () =>
          this.storeNewIndexes(
            parseInt(itemID),
            parseInt(itemMgID),
            newIndex
            // newIndex + ((this.activePage - 1) * this.itemsPerPage)
          ),
      },
    });
    //#endregion Sortablejs ---
  },

  computed: {
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
    restaurantId() { return this.$route.params.restaurant_id },
    itemId() { return this.$route.params.id }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      name: { required },
      notes: { },
      restaurant_id: { required },
      categories: { },
    },
  },

  methods: {
    //#region Menu Groups (ingredients) Choice
    selectedTypeListing(mg_id, value) {
      var mg = this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id && mg.typeListing === value);
      return mg != null;
    },
    changeTypeListing(mg_id, e) {
      var mg = this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id);
      if(mg) {
        mg.typeListing = e.target.value;
      }
      else
        this.form.selectedMenuGroups.push({
          menuExtGroupId: mg_id,
          typeListing: e.target.value
        });
    },

    selectedValidDays(mg_id) {
      var mg = this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id);
      return mg?.validDaysOfWeek;
    },
    updateValidDays(mg_id, day) {
      var mg = this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id);
      var array = mg?.validDaysOfWeek ?? [];
      array.toggle(day);

      mg.validDaysOfWeek = array.length ? array : null;
    },

    selectMenuGroup(mg_id, value) {
      const mg = this.allMenuGroups.find(mg => mg.id == mg_id);
      mg.selected = value ? 1 : 0;

      if(value) { // checked
        this.form.selectedMenuGroups.push({
          menuExtGroupId: mg_id,
          typeListing: 'SLT',
          selected: true,

          id: 0,
          Index: this.form.selectedMenuGroups.length > 0
            ? Math.max(...this.form.selectedMenuGroups.map(smg => smg.Index)) + 1
            : 1
          // Index: this.form.selectedMenuGroups.length + 1
        });
      } else {    // unchecked
        const index = this.form.selectedMenuGroups.findIndex(mg => mg.menuExtGroupId == mg_id);
        this.form.selectedMenuGroups.splice(index, 1);

        // Silinen menu gruba ait tüm childları da sil.
        var removeChilds = this.form.selectedChilds.filter((child) => {
          return child.menuExtGroupId == mg_id;
        });
        removeChilds.forEach((item) => {
          const index = this.form.selectedChilds.findIndex(child => child.menuExtGroupChildId === item.menuExtGroupChildId);
          this.form.selectedChilds.splice(index, 1);
        });
      }
    },
    isSelectedMenuGroup(mg_id) {
      return this.form.selectedMenuGroups.find(mg => mg.menuExtGroupId == mg_id) != null;
    },
    getSelectedMenuGroupId(mg_id) {
      let smg = this.form.selectedMenuGroups.find(smg => smg.menuExtGroupId == mg_id);
      return smg ? smg.id : 0;
    },
    selectingChild(mg_id) {
      var childs_count = this.form.selectedChilds.filter((child) => {
        return child.menuExtGroupId == mg_id;
      }).length;

      (childs_count == 0) && this.selectMenuGroup(mg_id, false);
      (childs_count == 1 && !this.isSelectedMenuGroup(mg_id)) && this.selectMenuGroup(mg_id, true);
    },
    //#endregion

    store() {
      window.scrollTo(0, 0);

      if (this.isValid) {
        this.submitted = true;

        this.$axios
          .post(
            this.$backend.MENU_SJABLOONS.STORE.replace("{id}", this.itemId),
            this.getRequestData()
            // this.form
          )
          .then((response) => {
            this.successAlertMessage = {itemName: response.data.name, message: 'menu sjabloon added.'}
            this.dismissSuccessAlert = this.dismissSecs
            this.reset();
          })
          .catch((error) => {
            this.errorAlertMessage = error.response.data;
            this.showErrorAlert = true;
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        this.validate();
      }
    },

    update() {
      window.scrollTo(0, 0);

      if (this.isValid) {
        this.submitted = true;

        this.$axios
          .put(
            this.$backend.MENU_SJABLOONS.UPDATE.replace("{id}", this.itemId),
            this.getRequestData()
            // this.form
          )
          .then((response) => {
            this.itemName = response.data.name;
            this.successAlertMessage = { itemName: this.itemName, message: "menu sjabloon updated." };
            this.dismissSuccessAlert = this.dismissSecs;
            this.showErrorAlert = false;

            // Menu grupları yenile.
            this.activePage = 1;
            this.allMenuGroups= [];
            this.getAllMenuGroups();
          })
          .catch((error) => {
            this.errorAlertMessage = error.response.data;
            this.showErrorAlert = true;
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        this.validate();
      }
    },

    getRequestData() {
      var categories = [];

      this.form.categories.forEach((category) => {
        categories.push({
          categories_id: category.id,
          disabled_menus: this.form.disabled_menus
            .filter((dm) => (dm.category_id == category.id && dm.menu_id !== ''))
            .map((dm) => dm.menu_id)
            .join()
        });
      });

      return {
        name: this.form.name,
        notes: this.form.notes,
        restaurant_id: this.form.restaurant_id,
        categories: categories,
        menu_groups: this.form.selectedMenuGroups,
        menu_group_childs: this.form.selectedChilds
      };
    },


    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    validate () {
      this.$v.$touch()
    },

    async reset () {
      this.form = await this.getForm()
      this.submitted = false
      this.showErrorAlert = false
      this.$v.$reset()
    },

    async getForm (id = null) {
      var form = {

        name: '',
        notes: '',
        restaurant_id: this.restaurantId ? this.restaurantId : null,
        categories: [],
        disabled_menus: [], //disabled menus in categories

        selectedMenuGroups: [],
        selectedChilds: [],
      };

      if(id) {
        await this.$axios.get(this.$backend.MENU_SJABLOONS.SHOW.replace("{id}", id))
          .then((res) => {
            const menu = res.data;

            form.name = menu.name;
            form.notes = menu.notes;
            form.restaurant_id = menu.restaurant_id;

            // Categories
            form.categories = this.allCategories.filter((c) =>
              menu.categories.map((dc) => dc.id).includes(c.id)
            );
            this.allCategories.map((dc)=>dc.label=dc.label + " | "+dc.menus.length);

            // Disabled Menus in Categories
            form.disabled_menus = menu.categories.flatMap((c) =>
              (c.pivot.disabled_menus ? c.pivot.disabled_menus : '')
                .split(",")
                .map((m) => ({ category_id: c.id, menu_id: m }))
            );

            // selectedMenuGroups
            menu.detail_ext_groups.forEach((item) => {
              form.selectedMenuGroups.push({
                id: item.id,
                menuExtGroupId: item.mnuextgrId,
                typeListing: item.TypeListing,
                Index: item.Index,
                validDaysOfWeek: item.valid_days_of_week,
                selected: true,
              });

              // selectedChilds
              item.childs.forEach((child) => {
                form.selectedChilds.push({
                  menuExtGroupId: item.mnuextgrId,
                  menuExtGroupChildId: child.megcId
                });
              });

            });

            this.itemName = menu.name;
          })
          .then(() => {
            // fix if there is an irregular sorting
            var index = 0;
            form.selectedMenuGroups.forEach((value) => { value.Index = ++index });
          })
          .catch((error) => {
            console.log(error);
            // this.$router.push(this.prevRoute)
          })
      }
      return form;
    },

    async getAllRestaurantNames() {
      await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;

          if(this.restaurantId) {
            this.selectedRestaurant = this.allRestaurants.find(item => item.value == this.restaurantId);
          }
        })
        .catch(() => { this.allRestaurants = [] })
    },

    async getRestaurantAllCategories(restaurant_id) {
      this.submitted = true
      this.form.categories = []

      await this.$axios.get(this.$backend.RESTAURANT_LINKED_CATEGORIES.GET_ALL_BY_RESTAURANT_ID_WITH_MENUS.replace("{restaurant_id}", restaurant_id))
        .then((response) => {
          this.allCategories = response.data
          this.allCategories.map((dc)=>dc.label=dc.label + " | "+dc.menus.length);
        })
        .finally(() => (this.submitted = false))
    },

    async getAllMenuGroups() { // Called when mounted
      this.submitted = true;

      const selectedExtGroup = this.form.selectedMenuGroups.map((group) => group.menuExtGroupId);

      var url = new URL(this.$backend.MENU_GROUPS.GET_ALL_WITH_CHILDS),
        params = {
          page: this.activePage,
          itemsPerPage: (Math.floor(selectedExtGroup.length / 5) + 1) * 5,
          selectedExtGroup: selectedExtGroup,
          search: this.search
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.allMenuGroups = this.allMenuGroups.concat(items.data);

            // Sort allMenuGroups by selectedMenuGroups (selected desc, sira asc)
            this.allMenuGroups.forEach(mg => {
              const smg = this.form.selectedMenuGroups.find(smg => smg.menuExtGroupId == mg.id);
              mg.sira = smg ? smg.Index : 0;
            });
            this.allMenuGroups.sort((a, b) => b.selected - a.selected || a.sira - b.sira);
            // this.allMenuGroups.sort((a, b) => b.sira - a.sira);

            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.to = items.to;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },

    async getNextMenuGroups() { // Called when mounted
      window.onscroll = () => {
        this.mcSpinnerMarginTop = document.documentElement.scrollTop;
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

        if (bottomOfWindow) {
          if (this.activePage < this.pages) {
            this.activePage++;
            this.getAllMenuGroups();
          }
        }
      }
    },


    storeNewIndexes(itemID, itemMgID, newIndex) {
      // console.log(itemID, itemMgID, newIndex);
      // console.log(this.form.selectedMenuGroups);
      // console.log(this.allMenuGroups);
      // return

      if(isNaN(itemID) || isNaN(newIndex))
        return;

      // Dizi index'i 0'dan başlıyor, ancak bizim 0. elemanın index'i 1'den başlıyor.
      newIndex++;

      // For Front-End
      var item = this.form.selectedMenuGroups.find(item => item.id == itemID && item.menuExtGroupId == itemMgID);
      var oldIndex = item.Index;
      if (newIndex > oldIndex) {
        this.form.selectedMenuGroups.filter((item) => {
          return item.Index > oldIndex && item.Index <= newIndex;
        }).forEach((value, index) => { value.Index--; });
      }
      else if (newIndex < oldIndex) {
        this.form.selectedMenuGroups.filter((item) => {
          return item.Index < oldIndex && item.Index >= newIndex;
        }).forEach((value, index) => { value.Index++; });
      }
      item.Index = newIndex;
    }
  }
}
</script>

<style scoped>
div.sticky-search {
  position: -webkit-sticky;
  position: sticky;
  z-index: 98;
  top: 100px;

  margin-top: 1.5rem;
  padding: 0.5rem;
  background-color: #ebedef;
  border-radius: 5px;
}

ul {
  flex-wrap: wrap;
  display: flex;

  list-style-type: none;
  background-color: #e6e6fa;
  border-radius: 5px;
  margin: 0;
  padding: 10px 20px;
}
ul li {
  flex: 0 0 100%;
}
@media (min-width: 576px) {
  ul li {
    flex: 0 0 50%;
  }
}
@media (min-width: 1200px) {
  ul li {
    flex: 0 0 25%;
  }
}

.disable-item {
  pointer-events: none;
  opacity: 0.6;
}
.enable-item {
  pointer-events: auto;
  opacity: 1;
}
.enable-item, .move-item {
  vertical-align: top !important;
}
.selected-item {
  border-left: 5px solid #2eb85c;
}
label.for-checkbox, label.for-checkbox > input {
  cursor: pointer;
}

.category-menu {
  margin-bottom: 1rem;
}
.category-menu hr {
  margin-top: 0;
  margin-bottom: 0;
}
.category-menu ul {
  flex-wrap: wrap;
  display: flex;

  list-style-type: none;
  background-color: #ebedef;
  border-radius: 0 0 5px 5px;
  margin: 0;
  padding: 10px 10px 0;
}
.category-menu ul li {
  flex: 0 0 25%;
}
</style>

<style>
.custom-checkbox-label {
  font-size: 18px;
  font-weight: 500;
}
.custom-control-input:checked ~ .custom-checkbox-label::before {
  border-color: #2eb85c;
  background-color: #2eb85c;
}

.day-group .form-check {
  padding-left: 0 !important;
}
.day-group .form-check-input {
  display: none;
}
.day-group .form-check-label {
  font-weight: normal;
  text-align: center;
  width: 32px;
  cursor: pointer;
  padding: 0.275rem;
  border: 1px solid rgba(60,60,60,.26);
  border-radius: 50%;
}
.day-group input[type=checkbox]:checked + .form-check-label {
  background-color: #d5f1de;
  border-color: #c4ebd1;
}
</style>
